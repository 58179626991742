<!--
 * @Author: dingguowei
 * @Date: 2023-05-18 14:58:23
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-27 16:23:12
-->

<!-- 登录页面 -->
<template>
  <div class="LoginPage">
    <!-- 主体登录内容 -->
    <div class="login">
      <!-- 修饰标头 -->
      <div class="title">
        <template v-if="process === 'userLogin'">
          <p style="margin-left: 10px">用户名登录</p>
        </template>
        <template v-else-if="process === 'phoneLogin'">
          <p style="margin-left: 10px">手机号登录</p>
        </template>
        <template v-else-if="process === 'register'">
          <p style="margin-left: 10px">新用户注册</p>
          <p
            @click="changeprocess('userLogin')"
            style="
              font-size: 0.6rem;
              margin-left: auto;
              color: #3580ff;
              font-weight: 400;
              cursor: pointer;
            "
          >
            有账号登录
          </p>
        </template>
        <template v-else-if="process === 'findPassword'">
          <p style="margin-left: 10px">用户名登录</p>
        </template>
      </div>
      <!-- 表单提交模块 -->
      <div class="con">
        <!--昵称登录模块 -->
        <el-form
          class="formbody"
          v-if="process === 'userLogin'"
          label-position="left"
          label-width="0px"
          :model="userLogin"
          ref="login"
        >
          <el-form-item
            label=""
            prop="account"
            :rules="[
              { required: true, message: '请输入用户名', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model.trim="userLogin.account"
              placeholder="请输入用户名"
              @keyup.enter.native="submitForm('login')"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item
            label=""
            prop="password"
            :rules="[
              { required: true, message: '请输入密码', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model.trim="userLogin.password"
              type="password"
              placeholder="请输入密码"
              @keyup.enter.native="submitForm('login')"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-row class="handel">
            <el-checkbox v-model="rememberPrd">记住密码</el-checkbox>
            <span @click="changeprocess('phoneLogin')">手机号登录</span>
          </el-row>
          <el-row class="btn">
            <el-button type="primary" @click="submitForm('login')"
              >登录</el-button
            >
            <p @click="changeprocess('register')">没有账户马上注册</p>
          </el-row>
        </el-form>
        <!--手机号登录模块 -->
        <el-form
          class="formbody"
          v-if="process === 'phoneLogin'"
          label-position="left"
          label-width="0px"
          :model="phoneLogin"
          :rules="loginrules"
          ref="login"
        >
          <el-form-item label="" prop="mobile">
            <el-input
              v-model.trim="phoneLogin.mobile"
              placeholder="请输入手机号"
              @keyup.enter.native="submitForm('login')"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <div style="display: flex" class="code">
              <el-input
                v-model.trim="phoneLogin.code"
                type="password"
                placeholder="验证码"
                style="flex: 1"
                @keyup.enter.native="submitForm('login')"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i
              ></el-input>
              <el-button
                :disabled="btndisabled"
                size="mini"
                style="margin-left: 10px; display: inline-block"
                @click="getphonecode"
                >{{ btnContent }}</el-button
              >
            </div>
          </el-form-item>
          <el-row class="handel">
            <!-- <el-checkbox v-model="rememberPrd">记住密码</el-checkbox> -->
            <span @click="changeprocess('userLogin')">用户名登录</span>
          </el-row>
          <el-row class="btn">
            <el-button type="primary" @click="submitForm('login')"
              >登录</el-button
            >
            <p @click="changeprocess('register')">没有账户马上注册</p>
          </el-row>
        </el-form>
        <!-- 新用户注册模块 -->
        <el-form
          v-else-if="process === 'register'"
          label-position="left"
          label-width="0px"
          :model="registerInfor"
          :rules="registerrules"
          ref="register"
        >
          <el-form-item label="" prop="account">
            <el-input
              v-model.trim="registerInfor.account"
              placeholder="请输入昵称"
              onkeyup="value=value.replace(/[^\x00-\xff]/g, '')"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <el-input
              v-model.trim="registerInfor.mobile"
              placeholder="请输入手机号"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <div style="display: flex" class="code">
              <el-input
                v-model.trim="registerInfor.code"
                type="password"
                placeholder="验证码"
                style="flex: 1"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i
              ></el-input>
              <el-button
                :disabled="btndisabled"
                size="mini"
                style="margin-left: 10px; display: inline-block"
                @click="getphonecode"
                >{{ btnContent }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              v-model.trim="registerInfor.password"
              placeholder="请输入密码"
              type="password"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="invitationCode">
            <el-input
              v-model.trim="registerInfor.invitationCode"
              placeholder="邀请码"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-row class="btn">
            <el-button type="primary" @click="register">确认注册</el-button>
          </el-row>
        </el-form>
        <!-- 忘记密码模块 -->
        <el-form
          v-else-if="process === 'findPassword'"
          label-position="left"
          label-width="80px"
          :model="findPasswordInfor"
          :rules="rules"
          ref="findPassword"
        >
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model.trim="findPasswordInfor.mobile">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱地址" prop="email">
            <el-input v-model.trim="findPasswordInfor.email">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="mobile">
            <el-input v-model.trim="findPasswordInfor.mobile">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model.trim="findPasswordInfor.newPassword"
              type="password"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="邀请码" prop="invitationCode">
            <el-input v-model.trim="registerInfor.invitationCode">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i
            ></el-input>
          </el-form-item>
          <el-row class="btn">
            <el-button type="primary" @click="submitForm('register')"
              >注册</el-button
            >
            <el-button @click="changeprocess('userLogin')"
              >已有账号登录</el-button
            >
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { userlogin, save, phonemsg, phonelogin } from "@/api/usercontroller";
import store from "@/store";

export default {
  name: "Name",
  components: {},
  data() {
    // 检验手机号
    var checkphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        const regMobile =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (regMobile.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      //用户登录填写的内容
      userLogin: {
        account: "",
        password: "",
      },
      // 手机登录填写的内容
      phoneLogin: {
        mobile: "",
        code: "",
      },
      // 是否记住密码
      rememberPrd: false,
      // 用户注册信息
      registerInfor: {
        account: "",
        invitationCode: "",
        mobile: "",
        code: "",
        openid: "",
        password: "",
        wxAvatar: "",
      },
      findPasswordInfor: {},
      // 表单规则
      loginrules: {
        mobile: [{ validator: checkphone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      registerrules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度请控制在3-10位之间",
            trigger: "blur",
          },
        ],
        mobile: [{ validator: checkphone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入正确的验证码", trigger: "blur" },
        ],
      },
      checkList: [],
      // 当前步骤
      process: "userLogin",
      time: 0,
      btndisabled: false,
    };
  },
  computed: {
    // 将content属性渲染为HTML代码，并实现打字效果
    btnContent() {
      if (this.time > 0) {
        return this.time + "s后重获";
      } else {
        return "获取验证码";
      }
    },
  },
  mounted() {
    // this.getcode();
  },
  methods: {
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === "login") {
            this.login();
          } else if (formName === "register") {
            this.register();
          } else {
            this.findPassword();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 登录
    async login() {
      let loginFun;
      let param;
      if (this.process === "userLogin") {
        loginFun = userlogin;
        param = this.userLogin;
      } else {
        loginFun = phonelogin;
        param = this.phoneLogin;
      }
      await loginFun(param)
        .then((data) => {
          if (data.code === 1000) {
            store.commit("user/CHANGE_userInfo", data.data);
            // localStorage.setItem("userInfo", JSON.stringify(data.data));
            localStorage.setItem("token", data.data.token);
            if(data.data.userType >= 10) {
this.$router.replace("/channelpage");
            } else {
 this.$router.replace("/home");
            }
            this.$message({
              message: "登录成功",
              type: "success",
              center: true,
            });
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
              center: true,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 注册新用户
    async register() {
      await save(this.registerInfor).then((data) => {
        if (data.code === 1000) {
          store.commit("user/CHANGE_userInfo", data.data);
          localStorage.setItem("token", data.data.token);
          this.$router.replace("/allapp");
          this.$message({
            message: "注册成功",
            type: "success",
            center: true,
          });
        } else {
          this.$message({
            message: data.msg,
            type: "warning",
            center: true,
          });
        }
      });
    },
    // 手机获得验证码
    async getphonecode() {
      let param;
      if (this.process === "phoneLogin") {
        param = {
          mobile: this.phoneLogin.mobile.toString(),
        };
      } else {
        param = {
          mobile: this.registerInfor.mobile.toString(),
        };
      }
      await phonemsg(param).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "发送成功，请查看短信",
            type: "success",
          });

          this.time = 60;
          this.btndisabled = true;
          var _this = this;
          // 启动定时器
          var timer = setInterval(() => {
            if (_this.time > 0) {
              _this.time = _this.time - 1;
            } else {
              _this.time = 0;
              this.btndisabled = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 改变步骤
    async changeprocess(val) {
      if (this.$refs.login) {
        this.$refs.login.resetFields();
      } else if (this.$refs.register) {
        this.$refs.register.resetFields();
      }
      this.process = val;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.LoginPage {
  height: calc(100%);
  background: url("~@/assets/login/1.png");
  background-size: 100% 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;

  .login {
    // background: url("~@/assets/loginpage/u3.svg");
    width: 18rem;
    margin-left: auto;
    user-select: text;
    // height: 27rem;
    margin-right: 10rem;
    background-color: rgb(255, 255, 255);
    border-radius: 19px;
    // background-color: #fff;
    padding: 0px 20px;
    ::v-deep .el-input__inner {
      background-color: transparent;
      color: rgb(8, 8, 8);
      font-size: 0.8rem;
      height: 36px;
    }
    ::v-deep .el-form-item__content {
      display: flex;
    }
    ::v-deep .el-button {
      font-size: 0.7rem;
    }
    .title {
      width: 100%;
      font-size: 1.4rem;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #000000;
      line-height: 33px;
      padding: 1.5rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .titledecorate {
        height: 1.2rem;
        width: 6px;
        background-color: #03b4f5;
      }
      .explain {
        margin-left: auto;
        font-size: 13px;
        color: rgba(21, 21, 21, 0.647);
      }
    }
    .con {
      text-align: left;
      min-height: 20rem;
      overflow: auto;

      .btn {
        margin-top: 1rem;
        margin-bottom: 1rem;
        // display: flex;
        // align-content: center;
        ::v-deep .el-button--primary {
          margin: auto;
          width: 100%;
          font-size: 0.7rem;
          padding: 0 2rem;
          // line-height: 1rem;
          background-color: #3580ff;
          height: 2rem;
        }
        ::v-deep .el-button--default {
          margin: auto;
          // width: 10rem;
          font-size: 0.7rem;
          padding: 0.4rem 1.4rem;

          // line-height: 1rem;
          color: black;
          background-color: rgb(255, 255, 255);
          height: 2.2rem;
        }
        & p {
          cursor: pointer;
          margin-top: 10px;
          text-align: center;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #4d9bf1;
          line-height: 21px;
        }
      }
      .handel {
        display: flex;
        ::v-deep .el-checkbox__label {
          font-size: 0.8rem;
        }
        & span {
          cursor: pointer;
          font-weight: 400;
          color: #3580ff;
          font-size: 0.8rem;
          margin-left: auto;
        }
      }
      .code {
        align-items: center;
        ::v-deep .el-button {
          height: calc(100% - 4px);
        }
      }
      // padding: 30px;
    }
  }
}
</style>
  