/*
 * @Author: dingguowei
 * @Date: 2023-04-23 15:25:42
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 19:48:49
 */
import Vue from 'vue'
// import Router from 'vue-router'
import store from '@/store';
import VueRouter from 'vue-router'


Vue.use(VueRouter)



// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};




const routes = [{
    path: '/',
    // redirect: '/mainpage',
    beforeEnter: (to, _, next) => {
      //const auth = (store.state.user.userInfo.userType === 1 || store.state.user.userInfo.userType === 0) ? true : false
      if (store.state.user.userInfo.userType === 1 || store.state.user.userInfo.userType === 0) {
        next({
          path: '/mainpage'
        })
      } else if (store.state.user.userInfo.userType >= 10) {
        next({
          path: '/channelpage'
        })
      } else {
        next({
          path: '/usermainpage'
        })
      }
    },
  },
  // 管理端
  {
    path: '/mainpage',
    name: '初始页面',
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    },
    redirect: '/home',
    component: () => import('@/views/mainpage/mainPage'),
    children: [{
        path: '/home',
        component: () => import('@/views/home/home'),
        children: [{
          path: '/',
          name: '首页',
          component: () => import('@/views/home/home'),
        }, ]
      },
      {
        path: '/taskMgr',
        component: () => import('@/views/taskMgr/taskMgr.vue'),
        children: [{
            path: '/',
            name: '发布任务管理',
            component: () => import('@/views/taskMgr/taskMgt'),
          },
          {
            path: 'taskdetails',
            name: '发布任务详情',
            component: () => import('@/views/taskMgr/taskDetails'),
          },
          {
            path: 'publishtask',
            name: '发布任务',
            component: () => import('@/views/taskMgr/publishTask'),
          },
          {
            path: 'edittask',
            name: '编辑任务',
            component: () => import('@/views/taskMgr/editTask'),
          },
          {
            path: '/auditMgt',
            name: '审核任务管理',
            component: () => import('@/views/taskMgr/auditMgt'),
          },
          {
            path: 'auditdetails',
            name: '审核任务详情',
            component: () => import('@/views/taskMgr/auditDetails'),
          },
        ]
      },
      {
        path: '/usermanager',
        name: '用户管理',
        component: () => import('@/views/user/usermanager'),
      },
      {
        path: '/userMgtDetails',
        name: '用户管理详情页',
        component: () => import('@/views/user/userMgtDetails'),
      },
      {
        path: '/concretecontent',
        name: '查看用户任务具体内容',
        component: () => import('@/views/user/concretecontent'),
      },
      {
        path: '/examinesocial',
        name: '查看用户任务具体内容',
        component: () => import('@/views/taskMgr/examinesocial'),
      },
      {
        path: '/contentmgt',
        name: '内容管理',
        component: () => import('@/views/taskMgr/contentMgt'),
      },
      {
        path: '/contentmgt',
        name: '内容管理详情',
        component: () => import('@/views/taskMgr/contentDetails'),
      },
      {
        path: '/notemgt',
        name: '笔记管理',
        component: () => import('@/views/noteMgt/noteMgt'),
      },
      {
        path: '/order',
        name: '订单类目查询',
        component: () => import('@/views/order/order.vue'),
      },
      {
        path: '/jdorder',
        name: '麦炫订单查询',
        component: () => import('@/views/jdorder/jdorder.vue'),
      },
      {
        path: '/lgorder',
        name: '灵感订单查询',
        component: () => import('@/views/jdorder/lgorder.vue'),
      },
      {
        path: '/theme',
        name: '麦炫项目密令词效果查询',
        component: () => import('@/views/secret/theme.vue'),
      },
      {
        path: '/secret/:themeId',
        name: '项目密令词效果查询',
        component: () => import('@/views/secret/secret.vue'),
      },
      {
        path: '/lgtheme',
        name: '灵感项目密令词效果查询',
        component: () => import('@/views/secret/lgtheme.vue'),
      },
      {
        path: '/lgsecret/:themeId',
        name: '灵感项目密令词效果查询',
        component: () => import('@/views/secret/lgsecret.vue'),
      },
      {
        path: '/jdactivity',
        name: '麦炫活动查询',
        component: () => import('@/views/jdactivity/jdactivity.vue'),
      },
      {
        path: '/daily',
        name: '每日数据',
        component: () => import('@/views/jdactivity/activitydaily.vue'),
      },
      {
        path: '/lgactivity',
        name: '灵感活动查询',
        component: () => import('@/views/jdactivity/lgactivity.vue'),
      },
      {
        path: '/lgdaily',
        name: '灵感每日数据',
        component: () => import('@/views/jdactivity/lgactivitydaily.vue'),
      },
      {
        path: '/projectorder',
        name: '麦炫订单效果查询',
        component: () => import('@/views/projectorder/projectorder.vue'),
      },
      {
        path: '/xhm',
        name: '灵感小红盟查询',
        component: () => import('@/views/xhm/xhm.vue'),
      },
      {
        path: '/mxxhm',
        name: '麦炫小红盟查询',
        component: () => import('@/views/xhm/mxXhm.vue'),
      },
      {
        path: '/xhmDaily',
        name: '灵感小红盟每日数据',
        component: () => import('@/views/xhm/xhmDetail.vue'),
      },
      {
        path: '/mxXhmDaily',
        name: '麦炫小红盟每日数据',
        component: () => import('@/views/xhm/mxXhmDetail.vue'),
      },
      {
        path: '/secretHistory',
        name: '密令词历史',
        component: () => import('@/views/secret/secrethistory.vue'),
      },
      {
        path: '/lgsecretHistory',
        name: '灵感密令词历史',
        component: () => import('@/views/secret/lgsecrethistory.vue'),
      },
      {
        path: '/jdexportorder',
        name: '麦炫导出订单',
        component: () => import('@/views/jdexport/exportorder.vue'),
      },
      {
        path: '/jdlgexportorder',
        name: '灵感导出订单',
        component: () => import('@/views/jdexport/lgexportorder.vue'),
      },
      {
        path: '/violateorder',
        name: '麦炫违规订单',
        component: () => import('@/views/orderstat/project.vue'),
      },
      {
        path: '/violateorders/:projectId',
        name: '麦炫违规订单详情',
        component: () => import('@/views/orderstat/violateOrders.vue'),
      },
      {
        path: '/lgviolateorder',
        name: '灵感违规订单',
        component: () => import('@/views/orderstat/lgproject.vue'),
      },
      {
        path: '/lgviolateorder/:projectId',
        name: '麦炫违规订单详情',
        component: () => import('@/views/orderstat/lgViolateOrders.vue'),
      },
      {
        path: '/exportjdorder',
        name: '京东密令订单导出',
        component: () => import('@/views/exportjdorder/exportjdorder.vue'),
      },
      {
        path: '/importmxorder',
        name: '麦炫订单导入',
        component: () => import('@/views/importorder/mxorder.vue'),
      },
      {
        path: '/importlgorder',
        name: '灵感订单导入',
        component: () => import('@/views/importorder/lgorder.vue'),
      },
      {
        path: '/mxactivity/order',
        name: '麦炫奖励活动订单统计',
        component: () => import('@/views/activityorder/mxactivity.vue'),
      },
      {
        path: '/lgactivity/order',
        name: '灵感奖励活动订单统计',
        component: () => import('@/views/activityorder/lgactivity.vue'),
      },
      {
        path: '/mx/activityorders/:activityId',
        name: '麦炫奖励活动违规订单详情',
        component: () => import('@/views/activityorder/mxactivityorders.vue'),
      },
      {
        path: '/lg/activityorders/:activityId',
        name: '灵感奖励活动违规订单详情',
        component: () => import('@/views/activityorder/lgactivityorders.vue'),
      },
    ]
  },
  // 用户端
  {
    path: '/usermainpage',
    name: '用户初始页面',
    redirect: '/userhome',
    meta: {
      requiresAuth: false,
      requiresAdmin: true
    },
    component: () => import('@/views/mainpage/mainPage'),
    children: [
      // 用户端
      {
        path: '/userhome',
        name: '用户首页',
        component: () => import('@/views/user/userhome'),
      },
      {
        path: '/taskhall',
        name: '任务大厅',
        component: () => import('@/views/user/taskHall'),
      },
      {
        path: '/mytask',
        name: '我的任务',
        component: () => import('@/views/user/myTask')
      },
      {
        path: '/mywallet',
        name: '我的钱包',
        component: () => import('@/views/user/myWallet')
      },
      {
        path: '/userDetails',
        name: '用户接受任务详情',
        component: () => import('@/views/user/taskDetails.vue'),
      },
      {
        path: '/personalInfo',
        name: '个人信息',
        component: () => import('@/views/user/personalInfo'),
      },
    ]
  },
  // 渠道端
  {
    path: '/channelpage',
    name: '初始页面',
    redirect: '/mxactivity/order',
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    },
    component: () => import('@/views/activityorder/mxactivity.vue'),
    children: [{
        path: '/jdactivity',
        name: 'M活动查询',
        component: () => import('@/views/jdactivity/jdactivity.vue'),
      },
      {
        path: '/lgactivity',
        name: 'L活动查询',
        component: () => import('@/views/jdactivity/lgactivity.vue'),
      },
      {
        path: '/mxactivity/order',
        name: 'M奖励活动订单统计',
        component: () => import('@/views/activityorder/mxactivity.vue'),
      },
      {
        path: '/lgactivity/order',
        name: 'L奖励活动订单统计',
        component: () => import('@/views/activityorder/lgactivity.vue'),
      },
      {
        path: '/mx/activityorders/:activityId',
        name: 'M奖励活动违规订单详情',
        component: () => import('@/views/activityorder/mxactivityorders.vue'),
      },
      {
        path: '/lg/activityorders/:activityId',
        name: 'L奖励活动违规订单详情',
        component: () => import('@/views/activityorder/lgactivityorders.vue'),
      },
    ]
  },
  {
    path: '/loginPage',
    name: 'loginPage',
    component: () => import('@/views/loginpage/loginPage')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 管理端才能进去
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  // 用户端才能进去
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const token = localStorage.getItem('token') //认证token，无token跳转登录页面
  const auth = (store.state.user.userInfo.userType === 1 || store.state.user.userInfo.userType === 0) ? true : false
  const channel = (store.state.user.userInfo.userType >= 10) ? true : false
  if (channel) {
    next()
  } else if (to.name === "loginPage") {
    next()
  } else {
    //需要验证是否登录成功
    if (token) {
      // 管理员并且需要管理员登录的页面
      if (auth && requiresAuth) {
        next()
        // 用户并且用户登录的页面
      } else if (!auth && requiresAdmin) {
        next()
      }
      // 管理员到了用户页面
      else if (auth && !requiresAdmin) {
        next()
        // next(false)
        //   next({
        //     path:"/mainpage"
        // })
        // 用户到了管理员页面
      } else if (!auth && !requiresAdmin) {
        // next(false)
        next({
          path: "/usermainPage"
        })
      }

    } else {
      next({
        path: "/loginPage"
      })
    }
  }
})

export default router