<template>
<div>
  <div id="myChart" :style="{width: '100%', height: '380px'}" v-if="!channelFlag"></div>
   <el-input v-model="orderId" placeholder="请输入订单号" style="width:240px" @keyup.enter.native="searchOrder(orderId)"></el-input>
   <el-button type="primary"  @click="searchOrder(orderId)" >搜索</el-button>
    <el-table
      :data="tableData"
      border
      style="width: 100%" v-loading="loading" height="40%">
      <template slot="empty">
        <p class="emptyText">{{ tableEmptyText }}</p>
    </template>

       <el-table-column
       prop="orderTime"
        label="下单时间">
      </el-table-column>

      <el-table-column
        prop="parentId"
        label="父订单号">
      </el-table-column>

      <el-table-column
        prop="orderId"
        label="订单号">
      </el-table-column>

      <el-table-column
        prop="finishTime"
        label="完成时间">
      </el-table-column>

      <el-table-column
        prop="orderStatusStr"
        label="订单状态">
      </el-table-column>

      <el-table-column
        prop="bonusInvalidCodeStr"
        label="奖励状态">
      </el-table-column>

      <el-table-column
        prop="estimateCosPrice"
        label="预估计佣金额">
      </el-table-column>

      <el-table-column
        prop="actualCosPrice"
        label="实际计佣金额">
      </el-table-column>

      <el-table-column
        prop="skuId"
        label="skuid">
      </el-table-column>

      <el-table-column
        prop="skuName"
        label="商品名称">
      </el-table-column>


   </el-table>
</div>
</template>
<script>
import { getActivityStat,getActivityOrder } from "@/api/activitystat";
import store from '@/store';
import * as echarts from 'echarts'

  export default {
    
    data() {
      return {
        activityId:'',
        startTime:'',
        endTime:'',
        activityName:'',
         tableData: [],
      tableEmptyText:'暂无数据',
      loading: false,
      orderId: '',
      channelFlag:''
      };
    },
    mounted(){
       this.channelFlag = store.state.user.userInfo.userType >= 10 ?true:false;
       this.activityId = this.$route.query.activityId;
      this.startTime = this.$route.query.startTime;
      this.endTime = this.$route.query.endTime;
      this.activityName = this.$route.query.activityName;
if(!this.channelFlag) {this.search()}
    },
    methods: {
      
      async search(){
           this.loading = true;
await getActivityStat({activityId: this.activityId,startTime:this.startTime, endTime:this.endTime})
          .then((res) => {
            this.loading = false;
            if (res.code == 1000) {
// 提取X轴数据和Y轴数据
let data = res.data
let xData = data.map(item => item.sumDate.slice(5,10));
let ygUv = data.map(item => item.ygUv);
let uv = data.map(item => item.uv);
let estimateValidNum = data.map(item => item.estimateValidNum);
let estimateCosPrice = data.map(item => item.estimateCosPrice);
let actualCosPriceData = data.map(item => item.actualCosPrice);
let actualCosPrice = data.map(item => item.actualCosPrice);
let actualValidNum = data.map(item => item.actualValidNum);
var chartDom = document.getElementById("myChart");
      let myChart = echarts.init(chartDom);    
     // 使用ECharts的配置项进行配置
let option = {
  title: {
    text: this.activityName + '-奖励活动每日统计'
  },
  tooltip: {
					trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
					axisPointer: {// 坐标轴指示器，坐标轴触发有效
						type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					}
},
  legend: {
    data: ['预估uv', '奖励uv', '预估订单量','奖励订单量','预估订单金额','奖励订单金额'],
    top:"6%"
  },
  xAxis: {
    type: 'category',
    data: xData,
    axisLabel:{
            show:true,  //这里的show用于设置是否显示x轴下的字体 默认为true
　　　　　　　interval:0,  //可以设置成 0 强制显示所有标签。如果设置为 1，表示『隔一个标签显示一个标签』，如果值为 2，表示隔两个标签显示一个标签，以此类推。
　　
　　　　　　　textStyle:{   //textStyle里面写x轴下的字体的样式
               color:'#333',
               fontSize:13
            },
            rotate:45
       },
    axisLine:{
           show:true,  //这里的show用于设置是否显示x轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写x轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  yAxis: {
    type: 'value',
       //用于设置y轴的字体
    axisLabel:{
            show:true,  //这里的show用于设置是否显示y轴下的字体 默认为true
            textStyle:{   //textStyle里面写y轴下的字体的样式
               color:'#333',
               fontSize:13
            }
       },
     //用于设置y轴的那一条线
    axisLine:{
           show:true,  //这里的show用于设置是否显示y轴那一条线 默认为true
           lineStyle:{ //lineStyle里面写y轴那一条线的样式
             color:'#6FC6F3',
             width:2,    //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
           }
       }
  },
  dataZoom: [{
		show: true,
		endValue: 9//x轴少于10个数据，则显示全部，超过10个数据则显示前10个。
	}],
   series: [
    {
      name: '预估uv',
      type: 'line',
      data: ygUv,
      smooth: true,
    },
    {
      name: '奖励uv',
      type: 'line',
      data: uv,
      smooth: true,
    },
    {
      name: '预估订单量',
      type: 'line',
      data: estimateValidNum,
      smooth: true,
    },
    {
      name: '奖励订单量',
      type: 'line',
      data: actualValidNum,
      smooth: true,
    },
    {
      name: '预估订单金额',
      type: 'line',
      data: estimateCosPrice,
      smooth: true,
    },
    {
      name: '奖励订单金额',
      type: 'line',
      data: actualCosPrice,
      smooth: true,
    },
  ]
};
  
myChart.setOption(option);
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      })
    },
        
  async searchOrder(val){
    if(!val) {
      alert("请输入订单号")
    } else {
this.loading = true
        await getActivityOrder({orderId: val, activityId: this.activityId, startTime: this.startTime, endTime: this.endTime})
          .then((res) => {
this.loading = false
            if (res.code == 1000) {
              if(null == res.data) {
this.tableData = [];
            this.tableEmptyText = '暂无数据'
              } else {
this.tableData = res.data;
              this.total = res.data.total;
              }
              
          } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    }
        
    },
    }
  };
</script>

