<template>
 <div class="home">
 <el-scrollbar style="height: 100%">
    <div class="tabledata">
    <el-tag type="danger">搜索结果只针对当前页，如需搜索更多或未找到活动，请翻页搜索</el-tag>
    <el-input v-model="keyword" placeholder="请输入奖励活动关键词"></el-input>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      <el-table :data="filteredData" style="width: 100%"
    max-height="700" border v-loading="loading">
        <el-table-column prop="activityName" label="活动名称"> 
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间"> 
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间"> 
        </el-table-column>

       <el-table-column
        prop="orderSumRes.estimateValidNum"
        label="预估订单量" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.estimateCosPrice"
        label="预估计佣金额(GMV)" v-if="!channelFlag">
      </el-table-column>

       <el-table-column
        prop="orderSumRes.estimateBonus"
        label="预估订单金额" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.actualValidNum"
        label="奖励订单量" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.actualCosPrice"
        label="实际计佣金额(GMV)" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.actualBonus"
        label="奖励订单金额" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.ygUv"
        label="预估结算UV" v-if="!channelFlag">
      </el-table-column>

      <el-table-column
        prop="orderSumRes.uv"
        label="结算UV" v-if="!channelFlag">
      </el-table-column>
      <el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
              <span class="handel" @click="getActivityStat(scope.row)">查看每日数据</span>
          </template>
        </el-table-column>
      </el-table>
       
</div>
</el-scrollbar>
</div>
</template>
<script>
import { getActivity } from "@/api/lgactivity";
import store from '@/store';
import router from '@/router';
  export default {
    data() {
      return {
      tableData: [],
      currentPage: 1,
      pageSize: 50,
      total: 0,
      loading: false,
       keyword:'',
       channelFlag:''
      };
    },
  mounted() {
    this.channelFlag = store.state.user.userInfo.userType >= 10 ?true:false
    this.search();
  },
computed: {
    filteredData() {
      if (this.keyword === '') {
        return this.tableData;
      } else {
        return this.tableData.filter(item => item.activityName.includes(this.keyword));
      }
    }
  },
    methods: {
      async search(val){
          this.loading = true;
        await getActivity({
          pageNo: this.currentPage, 
          pageSize: this.pageSize
          }).then((res) => {
              this.loading = false;
            if (res.code == 1000) {
              if(res.data.code == 200) {
this.tableData = res.data.result[0].activityList;
      this.total = res.data.totalNum;
              } else{
                this.$message({
            message: res.data.message,
            type: "warning",
          });
              }
      
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },
    searchActivity(){
    this.filteredData = this.tableData.filter(item => item.name.includes(this.keyword));
},
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    },
    getActivityStat(val) {
      let routeData = router.resolve({ name: `灵感每日数据`,　　　　
        query: {
          activityId: val.activityId,
          startTime:val.startTime.slice(0,10), 
          endTime:val.endTime.slice(0,10),
          activityName:val.activityName
        } }) 
         window.open(routeData.href, '_blank');
    },
    
    }
};
</script>

