<!--
 * @Author: dingguowei
 * @Date: 2023-05-19 11:54:33
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-30 17:32:30
-->
<!-- 主页面 -->
<template>
  <div class="mainPage">
    <div class="header">
      <img
        class="headerTitle"
        :src="require('@/assets/header/logo.png')"
        alt=""
      />
      <div class="nav">
        <!-- <head-item></head-item> -->
      </div>
      <div class="config-center">
        <userpopover
          v-if="userInfo.userType === 3 || userInfo.userType === 2"
        ></userpopover>
        <el-dropdown @command="handleCommand" size="small" v-else>
          <span class="icon el-icon-user-solid">
            {{ userInfo.account
            }}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="a" icon="el-icon-circle-plus"
              >登录</el-dropdown-item
            > -->
            <el-dropdown-item command="b" icon="el-icon-circle-plus-outline"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="childMain">
      <el-container>
        <el-aside width="16rem">
          <left-side :active-index="activeNav" :menu-datas="towMenuData" />
        </el-aside>
        <el-main>
          <router-view class="childRouter" />
        </el-main>
      </el-container>
    </div>
    <guidedoc class="guidedoc"></guidedoc>
  </div>
</template>
  
<script>
import { navItems, navItemsAdmin, navItemsSuper,navItemsChannel } from "@/config/mainMenu";
import { mapState } from "vuex";
import store from "@/store";
import leftSide from "@/components/leftSide.vue";
import userpopover from "@/components/userpopover.vue";
import guidedoc from "@/components/guidedoc.vue";
import userpopoverVue from "@/components/userpopover.vue";
import router from "@/router/index";
export default {
  name: "MainPage",
  components: {
    leftSide,
    userpopover,
    guidedoc,
  },
  data() {
    return {
      data: "",
      fistactiveId: 0,
      asideWidth: "0px",
      towMenuData: [],
      twoDefalutActive: "",
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    activeNav() {
      return this.$route.path;
    },
    // userInfo() {
    //   return JSON.parse(localStorage.getItem("userInfo"));
    // },
  },
  watch: {},
  created() {},
  mounted() {
    // const userStatus = localStorage.getItem("userStatus");
    // switch (userStatus) {
    //   case "0": {
    //     this.elMenuList = navItemsAdmin;
    //     break;
    //   }
    //   case "1": {
    //     this.elMenuList = navItems;
    //   }
    // }
    this.initMenuStyle();
  },
  methods: {
    initMenuStyle() {
      const userType = this.userInfo.userType
      switch (true) {
        case userType === 1:
          this.towMenuData = navItems;
          break;
        case userType === 0:
          this.towMenuData = navItemsSuper;
          break;
        case userType === 2:
          this.towMenuData = navItemsAdmin;
          break;
        case userType === 3:
          this.towMenuData = navItemsAdmin;
          break;
        case userType >=10:
          this.towMenuData = navItemsChannel;
          break;
        default:
          localStorage.setItem("token", "");
          router.push({ path: "/loginPage" });
          break;
      }
    },
    changeAsideWidth(value) {
      this.asideWidth = value;
    },
    login() {
      console.log("this is replace");
      localStorage.removeItem("token");
      localStorage.removeItem("userStatus");

      sessionStorage.removeItem("fistItem");
      this.$router.replace("/loginPage");
    },
    configBtn() {
      console.log("this is config");
    },
    handleCommand(command) {
      console.log(command);
      this.login();
    },
  },
  beforeDestroy() {
    localStorage.setItem("token", "");
  },
};
</script>
  
<style lang="scss" scoped>
.mainPage {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  color: white;
  user-select: text;

  .header {
    user-select: text;
    position: absolute;
    display: flex;
    width: 100%;
    top: 0;
    height: 60px;
    line-height: 60px;
    min-width: 1400px;
    // background: url("~@/assets/header/background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: #13194c;
    color: rgb(86, 186, 240);
    z-index: 1000;

    .headerTitle {
      margin: 15px 9px 15px 16px;
      height: 30px;
      line-height: 60px;
      float: left;
    }

    .nav {
      padding-right: 15px;
      box-sizing: border-box;
      flex: 1;
      margin-left: 35px;
    }

    .config-center {
      width: 7rem;
      padding-right: 15px;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // display: none;
    }
  }

  .childRouter {
    // background-color: #fff;
    height: calc(100%);
    z-index: 1000;
    overflow: hidden;
  }

  .tooltip {
    z-index: 10000;
    position: absolute;
  }

  .timeclock {
    z-index: 1000;
    position: absolute;
    right: calc(50% - 200px);
    top: 100px;
  }

  //
  .childMain {
    width: 100%;
    position: absolute;
    min-width: 1400px;
    min-height: 700px;
    background-color: #bdbdbd;
    top: 60px;
    bottom: 0;
    height: calc(100% - 60px);

    .el-container {
      height: 100%;
    }

    .el-aside {
      margin-bottom: 0;
      background-color: #ffffff;
      color: #fff;
      // border-right: 1px solid #3000061a;
      padding: 0;
      text-align: center;
    }

    .el-main {
      background-color: #f4f6fa;
      padding: 0;
      color: white;
      text-align: left;

      .router-tab {
        overflow: auto;
        //  background-color:#afafe6;
        height: 100%;
      }
    }
  }

  .guidedoc {
    position: fixed;
    bottom: 60px;
    right: 40px;
  }
  ::v-deep .el-menu--horizontal.el-menu {
    background-color: #141732;
    // background: url("~@/assets/header/backbtnActive.png") no-repeat;
    color: #fff;
  }

  ::v-deep .el-menu {
    background-color: #ffffff;
  }

  ::v-deep .el-submenu__title:hover {
    background-color: #5d71e3;
  }
}
</style>
  