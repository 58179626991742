<template>
 <div class="home">
    <div class="tabledata">
      <el-table :data="tableData" border>
        <el-table-column prop="activityTitle" label="活动名称"> 
        </el-table-column>
        <el-table-column prop="orderNum" label="订单数" :formatter="orderFormat" v-if="!channelFlag"> 
        </el-table-column>
        <el-table-column prop="violateNum" label="违规订单数" :formatter="violateFormat" > 
        </el-table-column>
        <el-table-column prop="violateRate" label="违规比例" :formatter="violatePerFormat" v-if="!channelFlag"> 
        </el-table-column>
        <el-table-column prop="maxCreatedOn" label="更新时间"> 
        </el-table-column>
        <el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
              <el-button type="success" round class="handel" @click="getMxActivityOrders(scope.row.activityId)">查看违规订单</el-button>
              <el-button type="primary" round class="handel" @click="exportActivityOrders(scope.row.activityId)" v-if="!channelFlag">导出违规订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
</div>
</template>
<script>
import { getMxActivity,exportActivityOrder } from "@/api/activityorder";
import store from '@/store';
import router from '@/router';
  export default {
    data() {
      return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      channelFlag:''
      };
    },
  mounted() {
    this.channelFlag = store.state.user.userInfo.userType >= 10 ?true:false
    this.search();
  },

    methods: {
      orderFormat(row,column){
            return row.validateCount== 0 ?'未统计': Number(row.validateCount) + Number(row.violateCount);
      },
      violateFormat(row,column){
          return row.validateCount== 0 ?'未统计': row.violateCount;
      },
      violatePerFormat(row,column){
          return row.validateCount== 0 ?'未统计': Math.round(Number(row.violateCount)*100 / ((Number(row.validateCount) + Number(row.violateCount))))+"%";
      },
async exportActivityOrders(val) {
      this.loading = true;
      await exportActivityOrder({
                activityId:val
}
      ).then((res) => {
        this.loading = false;
        if (res.code === 1000) {
            const link = document.createElement("a");
            link.href = res.data;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
      async search(val){
        await getMxActivity({
          pageNo: this.currentPage, 
          pageSize: this.pageSize
          }).then((res) => {
            if (res.code == 1000) {
      this.tableData = res.data.records;
      this.total = res.data.total;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    },
    getMxActivityOrders(val) {
      router.push({ path: `/mx/activityorders/${val}` }) 
    }
    }
};
</script>

