/*
 * @Author: dingguowei
 * @Date: 2023-05-23 14:09:41
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 19:03:01
 */
export const navItems = [{
    menuId: 1,
    title: '首页',
    path: '/home',
    icon: 'iconfont icon-shouye',

  },

  {
    menuId: 2,
    title: '任务管理',
    path: '/taskMgr',
    icon: 'iconfont icon-renwudating',
  },
  {
    menuId: 3,
    title: '用户管理',
    path: '/usermanager',
    icon: 'iconfont icon-yonghuguanli',
  },
  {
    menuId: 4,
    title: '内容管理',
    path: '/contentmgt',
    icon: 'iconfont icon-renwudating',
  },
  {
    menuId: 5,
    title: '笔记管理',
    path: '/notemgt',
    icon: 'iconfont icon-process2',
  },
  // {
  //   menuId: 6,
  //   title: '订单类目查询',
  //   path: '/order',
  //   icon: 'iconfont icon-process2',
  // },
  {
    menuId: 7,
    title: '麦炫订单查询',
    path: '/jdorder',
    icon: 'iconfont icon-process2',
  },

  {
    menuId: 8,
    title: '灵感订单查询',
    path: '/lgorder',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 9,
    title: '灵感项目密令词查询',
    path: '/theme',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 10,
    title: '麦炫项目密令词查询',
    path: '/lgtheme',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 11,
    title: '麦炫活动查询',
    path: '/jdactivity',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 12,
    title: '灵感活动查询',
    path: '/lgactivity',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 13,
    title: '灵感小红盟查询',
    path: '/xhm',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 14,
    title: '麦炫订单导出',
    path: '/jdexportorder',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 15,
    title: '麦炫订单效果查询',
    path: '/projectorder',
    icon: 'iconfont icon-process2',
  },
  {
    menuId: 16,
    title: '麦炫小红盟查询',
    path: '/mxxhm',
    icon: 'iconfont icon-process2',
  },
]

export const navItemsSuper = [{
    menuId: 1,
    title: '首页',
    path: '/home',
    icon: 'iconfont icon-shouye',

  },

  // {
  //   menuId: 2,
  //   title: '任务管理',
  //   path: '/taskMgr',
  //   icon: 'iconfont icon-renwudating',
  // },
  // {
  //   menuId: 3,
  //   name: '用户管理',
  //   path: '/usermanager',
  //   icon: 'iconfont icon-yonghuguanli',
  //   children: [{
  //       menuId: 31,
  //       title: '用户管理',
  //       path: '/usermanager',
  //       icon: 'iconfont icon-renwudating',
  //     },
  //     {
  //       menuId: 32,
  //       title: '社交信息审核',
  //       path: '/examinesocial',
  //       icon: 'iconfont icon-renwudating',
  //     }
  //   ],

  // },
  // {
  //   menuId: 4,
  //   title: '内容管理',
  //   path: '/contentmgt',
  //   icon: 'iconfont icon-renwudating',
  // },
  // {
  //   menuId: 5,
  //   title: '笔记管理',
  //   path: '/notemgt',
  //   icon: 'iconfont icon-process2',
  // },
  // {
  //   menuId: 6,
  //   title: '订单类目查询',
  //   path: '/order',
  //   icon: 'iconfont icon-process2',
  // },
  {
    menuId: 6,
    name: '订单查询',
    path: '/jdorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 60,
        title: '麦炫订单查询',
        path: '/jdorder',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 61,
        title: '灵感订单查询',
        path: '/lgorder',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  {
    menuId: 7,
    name: '项目密令词查询',
    path: '/theme',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 70,
        title: '麦炫项目密令词查询',
        path: '/theme',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 71,
        title: '灵感项目密令词查询',
        path: '/lgtheme',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  {
    menuId: 8,
    name: '奖励活动查询',
    path: '/jdactivity',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 80,
        title: '麦炫活动查询',
        path: '/jdactivity',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 81,
        title: '灵感活动查询',
        path: '/lgactivity',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  {
    menuId: 9,
    name: '小红盟查询',
    path: '/xhm',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 91,
        title: '麦炫小红盟查询',
        path: '/mxXhm',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 92,
        title: '灵感小红盟查询',
        path: '/xhm',
        icon: 'iconfont icon-renwudating',
      },

    ],
  },
  {
    menuId: 10,
    name: '订单匹配导出',
    path: '/jdexportorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 100,
        title: '麦炫订单导出',
        path: '/jdexportorder',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 101,
        title: '灵感订单导出',
        path: '/jdlgexportorder',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  {
    menuId: 11,
    name: '密令词违规订单',
    path: '/violateorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 110,
        title: '麦炫密令违规订单',
        path: '/violateorder',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 111,
        title: '灵感密令违规订单',
        path: '/lgviolateorder',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  {
    menuId: 15,
    name: '奖励活动违规订单',
    path: '/activityorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 150,
        title: '麦炫奖励违规订单',
        path: '/mxactivity/order',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 151,
        title: '灵感奖励违规订单',
        path: '/lgactivity/order',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
  // {
  //   menuId: 12,
  //   name: '订单效果查询',
  //   path: '/projectorder',
  //   icon: 'iconfont icon-process2',
  //   children: [{
  //     menuId: 110,
  //     title: '麦炫订单效果查询',
  //     path: '/projectorder',
  //     icon: 'iconfont icon-renwudating',
  //   }],
  // },
  {
    menuId: 13,
    name: '京东密令订单导出',
    path: '/exportjdorder',
    icon: 'iconfont icon-process2',
    children: [{
      menuId: 110,
      title: '京东密令订单导出',
      path: '/exportjdorder',
      icon: 'iconfont icon-renwudating',
    }],
  },
  {
    menuId: 14,
    name: '京东订单导入',
    path: '/jdorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 140,
        title: '麦炫订单导入',
        path: '/importmxorder',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 141,
        title: '灵感订单导入',
        path: '/importlgorder',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },
]

export const navItemsAdmin = [{
    menuId: 1,
    title: '首页',
    path: '/userhome',
    icon: 'iconfont icon-shouye',
  },
  {
    menuId: 2,
    title: '任务大厅',
    path: '/taskhall',
    icon: 'iconfont icon-shouye',

  },

  {
    menuId: 3,
    title: '我的任务',
    path: '/mytask',
    icon: 'iconfont icon-renwudating',
  },
  {
    menuId: 4,
    title: '我的钱包',
    path: '/mywallet',
    icon: 'iconfont icon-yonghuguanli',
  }

]


export const navItemsChannel = [{
    menuId: 15,
    name: '奖励活动违规订单',
    path: '/activityorder',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 150,
        title: 'M奖励违规订单',
        path: '/mxactivity/order',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 151,
        title: 'L奖励违规订单',
        path: '/lgactivity/order',
        icon: 'iconfont icon-renwudating',
      }
    ],
  }, {
    menuId: 8,
    name: '奖励活动查询',
    path: '/jdactivity',
    icon: 'iconfont icon-process2',
    children: [{
        menuId: 80,
        title: 'M活动查询',
        path: '/jdactivity',
        icon: 'iconfont icon-renwudating',
      },
      {
        menuId: 81,
        title: 'L活动查询',
        path: '/lgactivity',
        icon: 'iconfont icon-renwudating',
      }
    ],
  },



]